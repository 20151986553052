<template>
  <div class="animated fadeIn">
    <div id="Dashboard">
      <div style="height: 48px;">
        <b-button
          size="sm"
          variant="primary"
          @click="
            () => {
              loadData(false);
            }
          "
        >
          <i class="icon-reload"></i> Обновить
        </b-button>
      </div>

      <Grid
        ref="requestsGrid"
        :items="gridData.members"
        :columns="gridData.columns"
        :classes="gridData.classes"
        :actions="gridData.actions"
        :index="gridData.index"
        :filter-key="gridData.searchQuery"
        :defaultSort="gridData.sortBy"
        :defaultSortOrder="-1"
        :isDataLoading="isPageLoading"
      ></Grid>
      <h6>Доход: {{ totalAmountUAH | formatCurrency }}</h6>
      <b-modal
        ref="userInfoModal"
        size="md"
        scrollable
        ok-only
        title="Данные о пользователе"
      >
        <div class="d-block text-center">
          <UserData
            v-if="typeof selectedUser.Id !== 'undefined'"
            :user="selectedUser"
          ></UserData>
        </div>
      </b-modal>

      <b-modal
        ref="memberInfoModal"
        size="lg"
        scrollable
        ok-only
        title="Данные о игроке"
      >
        <div class="d-block text-center">
          <MemberData
            v-if="typeof selectedMember.Id !== 'undefined'"
            ref="memberData"
            :member="selectedMember"
            :baseURL="baseURL"
            :images="selectedMemberImages()"
            :getRank="getRank"
          ></MemberData>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import API from "../../api/stalk.net";
import Grid from "../../components/common/Grid";
import UserData from "../../components/UserData";
import MemberData from "../../components/MemberData";

export default {
  name: "Members",
  components: {
    Grid,
    UserData,
    MemberData
  },
  data() {
    return {
      isPageLoading: false,
      gridData: {
        members: [],
        columns: [
          { title: "Id", path: "Id" },
          {
            title: "Email",
            path: "Member.User.Email",
            action: this.viewUserDataByRequestId
          },
          { title: "Город", path: "Member.User.City.Name" },
          {
            title: "Позывной",
            path: "Member.Callsign",
            action: this.viewMemberDataByRequestId
          },
          {
            title: "Фамилия",
            path: "Member.User.LastName",
            action: this.viewUserDataByRequestId
          },
          {
            title: "Имя",
            path: "Member.User.FirstName",
            action: this.viewUserDataByRequestId
          },
          { title: "Группировка", path: "Member.Fraction.Title" },
          // {
          //   title: "Наличие ПДА",
          //   path: "Pda",
          //   handler: str => {
          //     return API.getPdaVariantTitle(str);
          //   }
          // },
          {
            title: "Заплатил",
            path: "PayedAmountUAH",
            handler: str => {
              return Vue.filter("formatCurrency")(parseFloat(str));
            }
          },
          {
            title: "Тип оплаты",
            path: "PaymentType",
            handler: str => {
              return API.getPaymentTypeTitle(str);
            }
          },
          {
            title: "Дата заявки",
            path: "CreatedAt",
            handler: str => {
              return Vue.filter("formatDate")(str);
            }
          }
        ],
        index: { title: { path: "Member.Callsign" }, id: { path: "Id" } },
        classes: [
          {
            path: "PaymentType",
            equals: API.consts.PaymentType.Cash,
            type: "table-yellow"
          },
          {
            path: "PaymentType",
            equals: API.consts.PaymentType.Combatant,
            type: "table-green"
          },
          {
            path: "PaymentType",
            equals: API.consts.PaymentType.Free,
            type: "table-blue"
          }
        ],
        actions: [],
        searchQuery: "",
        sortBy: {}
      },
      selectedUser: {},
      selectedMember: {},
      selectedRequest: {},
      baseURL: API.baseURL,

      totalAmountUAH: 0.0
    };
  },
  methods: {
    loadData(sort = true) {
      let self = this;

      self.isPageLoading = true;
      API.private
        .getMembers()
        .then(resp => {
          self.gridData.members = resp.data;
          if (sort) {
            self.gridData.sortby = { title: "Id", path: "Id" };
            self.$refs.requestsGrid.sortBy(self.gridData.sortby);
          }

          self.totalAmountUAH = 0.0;
          self.gridData.members.forEach(m => {
            self.totalAmountUAH += parseFloat(m.PayedAmountUAH);
          });
          self.isPageLoading = false;
        })
        .catch(this.catchError);
    },
    viewUserDataByRequestId(rid) {
      let self = this;

      API.private
        .getUserByRequest(rid)
        .then(resp => {
          self.selectedUser = resp.data;
          self.$refs.userInfoModal.show();
        })
        .catch(self.catchError);
    },
    viewMemberDataByRequestId(rid) {
      let self = this;

      API.private
        .getMemberByRequest(rid)
        .then(resp => {
          self.selectedMember = resp.data;
          self.$refs.memberInfoModal.show();
        })
        .catch(this.catchError);
    },
    catchError(e) {
      let self = this;

      console.error(e);
      if (e.response && e.response.data && e.response.data.error) {
        console.error(e.response.data);
        switch (e.response.data.error) {
          case "ERR_BAD_JWT":
          case "ERR_BAD_AUTH":
          case "ERR_NOT_ALLOWED":
            {
              self.$router.replace("/login");
            }
            break;
        }
        self.showError({ message: e.response });
      }
      self.isPageLoading = false;
    },
    getRank(rank) {
      return API.getRankTitle(rank);
    },
    selectedMemberImages() {
      let self = this;
      let images = [];
      self.selectedMember.Photos.forEach(photo => {
        images.push(self.baseURL + photo.Path);
      });

      return images;
    }
  },
  computed: {
    confirmModalTitle() {
      if (typeof this.selectedRequest.Id === "undefined") {
        return "";
      }
      return (
        this.selectedRequest.Member.Callsign +
        " (" +
        this.selectedRequest.Member.Fraction.Title +
        ")"
      );
    },
    discountUntilDesc() {
      return (
        "* сколько игрок должен заплатить до " +
        Vue.filter("formatDate")(this.selectedRequest.Event.DiscountUntil)
      );
    },
    noDiscountUntilDesc() {
      return (
        "* сколько игрок должен заплатить после " +
        Vue.filter("formatDate")(this.selectedRequest.Event.DiscountUntil)
      );
    }
  },
  beforeMount: function() {
    this.loadData();
  },
  notifications: {
    showError: {
      title: "Ошибка",
      type: "error"
    }
  }
};
</script>
